import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import getConfig from 'next/config'
import React from 'react'

const { serverRuntimeConfig, publicRuntimeConfig } = getConfig()

// eslint-disable-next-line no-underscore-dangle
if (!Bugsnag._client) {
  Bugsnag.start({
    apiKey: serverRuntimeConfig.BUGSNAG_API_KEY || publicRuntimeConfig.BUGSNAG_API_KEY,
    plugins: [new BugsnagPluginReact(React)],
    enabledReleaseStages: ['production'],
    releaseStage: (process.env.VERCEL_ENV || process.env.NEXT_PUBLIC_VERCEL_ENV || 'development').toLowerCase(),
  })
}

export default Bugsnag